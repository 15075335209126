jQuery(document).ready(function() {
	// Add new line on enter (All text area fields)
	$('textarea').keypress(function(event) {
		if (event.which == 13) {
			event.preventDefault();
			var s = $(this).val();
			$(this).val(s+"\n");
		}
	});

	$('.form-group input, .form-group select').on('focus', function(event) {
		event.preventDefault();
		$(this).parent('.form-group').addClass('focus active');
	});

	$( ".form-group select" ).change(function() {
		$(this).parent('.form-group').removeClass('focus');
	});

	$('.form-group input, .form-group select').on('blur', function(event) {
		event.preventDefault();
		$(this).parent('.form-group').removeClass('focus');
		if( !$(this).val() ) {
			$(this).parent('.form-group').removeClass('active');
		}
	});
}); // --- DOM END --- //

// --- Custom Vaidation Rules--- // 
// Expire Year
$.validator.addMethod("expireYear", function(value, element) {
	var year = new Date(value).getFullYear();
	var curentYear = new Date().getFullYear();
	return this.optional(element) || year <= curentYear;
}, 'Please enter a valid year.');

// Model Year
$.validator.addMethod("modelYear", function(value, element) {
	return this.optional( element ) || /^(181[2-9]|18[2-9]\d|19\d\d|2\d{3}|30[0-3]\d|304[0-8])$/.test( value );
}, 'Please enter a valid year.');

// NIC
$.validator.addMethod("NIC", function(value, element) {
	return this.optional( element ) || /((^|, )([0-9]{9}[x|X|v|V])|([0-9]{10}))$/.test( value );
}, 'Please enter a valid NIC number.');

// Name
$.validator.addMethod("validName", function(value, element) {
	return this.optional( element ) || /^[a-zA-Z\s]+$/.test( value );
}, 'Please enter a valid Name.');

// Email
$.validator.addMethod("customemail", function(value, element) {
	return this.optional( element ) || /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test( value );
}, 'Please enter a valid email address.');


// --- Form Validations --- //
var loanvalidator =  $('#loan-form').validate({
	rules: { 
		options: {
			required: true
		},
		title: {
			required: true
		},
		first_name: {
			required: true,
			validName: true,
			maxlength: 50
		},
		last_name: {
			required: true,
			validName: true,
			maxlength: 50
		},
		nic: {
			NIC: true
		},
		phone: {
			required: true,
			number: true,
			maxlength: 10,
			minlength: 10
		},
		district: {
			required: true,
			maxlength: 50
		},
		city: {
			required: true,
			maxlength: 50
		}
	},
	messages: {
		options:{
			required: "Please select vehicle type."
		},
		title:{
			required: "Please enter your title."
		},
		first_name:{
			required: "Please enter your first name."
		},
		last_name:{
			required: "Please enter your last name."
		},
		nic: {
			required: 'Please enter your NIC',
			NIC: 'Please enter Valid NIC'
		},
		phone:{
			required: "Please enter your contact number.",
			maxlength: "Please enter at least 10 numbers.",
			minlength: "Please enter at least 10 numbers.",
		},
		district:{
			required: "District - Please select your District.",
		},
		city:{
			required: "City - Please select your City.",
		}
	},
	errorPlacement: function(error, element) {
		if (element.attr("name") == "options" ) {
			error.insertAfter(".vehicle-type-error");
		} else {
			error.insertAfter(element);
		}
	}
});

var loginFormValidator =  $('#login-form').validate({ 
	rules: {
		email: {
			required: true,
			customemail: true,
			maxlength: 50
		},
		password: {
			required: true
		}
	},
	messages: {
		email:{
			required: "Please enter your email.",
			customemail: "Please enter valid email."
		},
		password:{
			required: "Please enter your password."
		}
	}
});

var registerFormValidator =  $('#register_form').validate({
	ignore: [],
	rules: {
		first_name: {
			required: true,
			maxlength: 100,
		},
		last_name: {
			required: true,
			maxlength: 100,
		},
		telephone: {
			required: true,
			number: true,
			maxlength: 10,
			minlength: 10
		},
		nic: {
			NIC: true,
		},
		email: {
			required: true,
			customemail: true,
		},
		password: {
			required: true,
			minlength: 6,
		},
		password_confirmation: {
			required: true,
			equalTo: "#password",
			minlength: 6,
		} 
	},
	messages: {
		first_name: {
			required: "Please enter your first name."
		},
		last_name: {
			required: "Please enter your last name."
		},
		telephone: {
			required: "Please enter your telephone number."
		},
		nic: {
			NIC: "Please enter valid NIC."
		},
		email: {
			required: "Please enter your e-mail.",
			customemail: "Please enter valid email."
		},
		password: {
			required: "Please enter your password.",
			minlength: "Password must be at least 6"
		},
		password_confirmation: {
			required: "Confirm your password.",
			equalTo: "Password mismatch."
		}
	}
});

var passwordResetValidator =  $('#password-reset-form').validate({ 
	rules : {
		new_password : {
			required: true
		},
		confirm_password : {
			required: true,
			equalTo : "#new_password"
		}
	},
	messages: {
		new_password:{
			required: "Please enter your password."
		},
		confirm_password:{
			required: "Please re-enter your password.",
			equalTo : "New password and re-entered password do not match"
		}
	}
});

var contactFormValidator =  $('#contact_form').validate({ 
	rules: {
		first_name: {
			required: true,
			validName: true,
			maxlength: 50,
			minlength: 2
		},
		last_name: {
			required: true,
			validName: true,
			maxlength: 50,
			minlength: 2
		},
		telephone: {
			required: true,
			number: true,
			maxlength: 10,
			minlength: 10
		},
		email: {
			required: true,
			customemail: true,
			maxlength: 50
		},
		message: {
			required: true
		}
	},
	messages: {
		first_name:{
			required: "Please enter your first name.",
			minlength: "Please enter at least 2 numbers."
		},
		last_name:{
			required: "Please enter your last name.",
			minlength: "Please enter at least 2 numbers."
		},
		telephone:{
			required: "Please enter your contact number.",
			maxlength: "Please enter at least 10 numbers.",
			minlength: "Please enter at least 10 numbers.",
		},
		email:{
			required: "Please enter your email address.",
			customemail: "Please enter valid email address."
		},
		message:{
			required: "Please type in your message."
		}
	}
});

var inquiryFormValidator =  $('#inquiry_form').validate({
	rules: {
		reason: {
			required: true
		},
		first_name: {
			required: true,
			validName: true,
			maxlength: 50
		},
		last_name: {
			required: true,
			validName: true,
			maxlength: 50
		},
		telephone: {
			required: true,
			number: true,
			maxlength: 10,
			minlength: 10
		},
		email: {
			required: true,
			customemail: true,
			maxlength: 50
		},
		message: {
			required: true
		}
	},
	messages: {
		reason:{
			required: "Please enter a reason."
		},
		first_name:{
			required: "Please enter your first name."
		},
		last_name:{
			required: "Please enter your last name."
		},
		phone:{
			required: "Please enter your contact number.",
			maxlength: "Please enter at least 10 numbers.",
			minlength: "Please enter at least 10 numbers.",
		},
		email:{
			required: "Please enter your email address.",
			customemail: "Please enter valid email address."
		},
		message:{
			required: "Please type in your message."
		}
	}
});