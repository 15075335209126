var appPatPat = {};

    // Get Base URL
    var baseURL = window.location.origin;

    $(document).ready(function () {
        // Watch List Ajax Start //
        $(".add").click(function (e) {
            e.preventDefault();
            var ad_id = $(this).attr('data-adid');
            addWatchList(ad_id);
        });

        $(".remove").click(function (e) {
            e.preventDefault();
            var ad_id = $(this).attr('data-adid');
            removeWatchList(ad_id, 0);
        });

        $(".remove-watchList").click(function (e) {
            e.preventDefault();
            var ad_id = $(this).attr('data-adid');
            removeWatchList(ad_id, 1);
        });
        // Watch List Ajax Start //

        // Trigger Ajax on Enter - User Profile
        $('body').on('keypress', '.profile-wr.collapse.show input' , function (e) { 
            var key = e.which; if(key == 13)  { 
                $(".profile-wr.show button").trigger('click');
            } 
        });

        // Loan Fetch
        $('.dynamic').change(function () {
            if ($(this).val() !== '') {
                var select = $(this).attr("id");
                var value = $(this).val();
                var dependent = $(this).data('dependent');
                var _token = $('input[name="_token"]').val();
                $.ajax({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },
                    url: baseURL +"/loan/fetch",
                    method: "POST",
                    crossDomain: true,
                    data: {select: select, value: value, _token: _token, dependent: dependent},
                    success: function (result) {
                        $('#' + dependent).html(result);
                    }
                })
            }
        });

        // Search Suggestions
        $('#dealer-list #search').on('keyup', function(){

            var category = $('#dealer-list #category').val();

            if (category == 'vehicle') {
                category = '1'
            } else if (category == 'education') {
                category = '2'
            } else if (category == 'leisure') {
                category = '3'
            } else {
                category = '4'
            }

            var dealerCategory = $('#dealer-list #dealerType').val();
            var search = $('#dealer-list #search').val();

            searchVenders(category, dealerCategory, search);
        });

        $('.dealer-tab').on('click', function () {
            var dealerType = $(this).data("dealertype");
            $('#dealerType').val(dealerType);
        });

        // Dealer Search Suggestions
        $('body').on('click', '#vender-suggestions .item', function() {
            let name = $(this).text();
            $('#dealer-list #search').val(name);
            $('.vender-suggestion-wr').addClass('d-none');
            $('#dealer-list').submit();
        });


         // Profile Settings Page
        $( "#btnEditName" ).click(function () {
            var first = $('#first_name').val();
            var last = $('#last_name').val();
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax({
        
                type: 'post',
                crossDomain: true,
                dataType: 'json',
                url: baseURL +'/edit/user-details',
                data: {first: first, last: last},
                beforeSend: function () {
        
                    $('.preloader').fadeIn();
                    
                },
                success: function (response) {
        
                    $('.preloader').fadeOut();
                    $("#edit_name").attr("aria-expanded","false");
                    $("#display_name").text(response[0].first_name+' '+response[0].last_name);
                    $("#editName").removeClass("show");
        
                },
                error: function (xhr, ajaxOptions, thrownError) {
                    alert(xhr.status);
                    alert(xhr.responseText);
                    alert(thrownError);
                }
        
            });
        });
        
        $( "#btnEditEmail" ).click(function () {
        
            var count = $('#btnEditEmail').data('count');
        
            $(".evm-div").addClass("d-none");
            $(".email-verify-message").text("");
            
        
            if(count == '0'){
        
                $("#password-email").val("");
                $(".e-password").removeClass("d-none");
                $("#btnEditEmail").data( "count","1");
                
                // $(".evm-div").addClass("d-none");
                // $(".email-verify-message").text("");
            
            }else if(count == '1'){
        
                var password = $('#password-email').val();
        
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax({
                    type: 'post',
                    crossDomain: true,
                    // dataType: 'json',
                    url: baseURL +'/checkpassword',
                    data: {password: password},
                    beforeSend: function () {
                        $('.preloader').fadeIn();
                    },
                    success: function (response) {
        
                        $('.preloader').fadeOut();
        
                        if(response == '1'){
                            sendverificationcode();
                            $(".e-password").addClass("d-none");
                            $("#div-vc").removeClass("d-none");
                            $("#btnEditEmail").data( "count","2");
                            $(".evm-div").addClass("d-none");
                            $(".email-verify-message").text("");
                        
                        }else{
        
                            $("#btnEditEmail").data( "count","1");
                            $(".evm-div").removeClass("d-none");
                            $(".email-verify-message").text("your password is incorrect.");
        
                        }
                        
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                        console.log(xhr.status);
                        console.log(xhr.responseText);
                        console.log(thrownError);
                    }
                });
        
            }else{
        
                var verification = $('#verification').val();
                var email = $('#email').val();
        
                $.ajax({
                    type: 'post',
                    crossDomain: true,
                    dataType: 'json',
                    url: baseURL +'/edit/user-details',
                    data: {email: email,verification: verification},
                    beforeSend: function () {
            
                        $('.preloader').fadeIn();
                    
                    },
                    success: function (response) {
        
                        $('.preloader').fadeOut();
        
                        if(response[0].status == '1'){
        
                            $("#btnEditEmail").data( "count","0");
                            $("#verification").val('');
                            $("#div-vc").addClass('d-none');
                            $(".evm-div").removeClass("d-none");
                            $(".email-verify-message").text('verification code is expired please try again.');
                            // $(".email-1").removeClass("d-none");
        
                        }else if(response[0].status == '2'){
                        
                            $(".evm-div").removeClass("d-none");
                            $(".email-verify-message").text('verification code is incorrect.');
        
                        }else{
                            
                            $("#display_email").text(response[0].email);
                            $("#verification").val('');
                            $("#div-vc").addClass('d-none');
                            $(".evm-div").removeClass("d-none");
                            $(".email-verify-message").text('email is updated successfully.');
                            $("#btnEditEmail").data( "count","0");
        
                            
                        }  
            
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                        alert(xhr.status);
                        alert(xhr.responseText);
                        alert(thrownError);
                    }
                });
        
            }
        
        });
        
        $( "#btnEditPhone" ).click(function () {
        
            var count = $('#btnEditPhone').data('count');
            
            $(".phone-verification-code").addClass("d-none");
            $(".phone-verification-code-message").text("");
            
        
            if(count == '0'){
        
                $("#password-phone").val("");
                $(".p-password").removeClass("d-none");
                $("#btnEditPhone").data( "count","1");
            }else if(count == '1'){
        
                var password = $('#password-phone').val();
        
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax({
                    type: 'post',
                    crossDomain: true,
                    // dataType: 'json',
                    url: baseURL +'/checkpassword',
                    data: {password: password},
                    beforeSend: function () {
                        $('.preloader').fadeIn();
                    },
                    success: function (response) {
        
                        $('.preloader').fadeOut();
                        if(response == '1'){
                            sendotp();
                            // $(".p-password").addClass("d-none");
                            // $("#div-otp").removeClass("d-none");
                            // $("#btnEditPhone").data( "count","1");
                        
                        }else{
        
                            $("#btnEditPhone").data( "count","1");
                            $(".phone-verification-code").removeClass("d-none");
                            $(".phone-verification-code-message").text("your password is incorrect.");
        
                        }
                    
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                        console.log(xhr.status);
                        console.log(xhr.responseText);
                        console.log(thrownError);
                    }
                });
        
            }else{
        
                var phone = $('#phone_number').val();
                var otp = $('#otp').val();
        
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
        
                $.ajax({
                    type: 'post',
                    crossDomain: true,
                    dataType: 'json',
                    url: baseURL +'/edit/user-details',
                    data: {phone: phone,otp: otp},
                    beforeSend: function () {
                        $('.preloader').fadeIn();
                    },
                    success: function (response) {
        
                        console.log(response);
        
                        $('.preloader').fadeOut();
                        
                        if(response[0].status == '1'){
        
                            $("#btnEditPhone").data( "count","0");
                            $("#otp").val('');
                            $("#div-otp").addClass('d-none');
                            $(".phone-verification-code").removeClass("d-none");
                            $(".phone-verification-code-message").text("verification code is expired please try again.");
        
                        }else if(response[0].status == '2'){
                        
                            $(".phone-verification-code").removeClass("d-none");
                            $(".phone-verification-code-message").text("verification code is incorrect.");
        
                        }else{
                            
                            // $("#edit_phone").attr("aria-expanded","false");
                            $("#display_phone").text(response[0].telephone);
                            // $("#editPhoneNo").removeClass("show");
                            $("#otp").val('');
                            $("#div-otp").addClass('d-none');
                            $(".phone-verification-code").removeClass("d-none");
                            $(".phone-verification-code-message").text("phone number is updated successfully.");
                            $("#btnEditPhone").data( "count","0");
        
                            
                        }  
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                        console.log(xhr.status);
                        console.log(xhr.responseText);
                        console.log(thrownError);
                    }
                });
            }
        
        });
        
        $( "#pw_update" ).click(function () {
            
            var current_pw = $('#current_password').val();
            var new_pw = $('#new_password').val();
            var confirm_pw = $('#confirm_password').val();
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax({
                type: 'post',
                crossDomain: true,
                dataType: 'json',
                url: baseURL +'/edit/user-details',
                data: {current_pw: current_pw, new_pw: new_pw, confirm_pw: confirm_pw},
                beforeSend: function () {
        
                    $('.preloader').fadeIn();
                
                },
                success: function (response) {
        
                    $('.preloader').fadeOut();
                    $("#edit_password").attr("aria-expanded","false");
                    $("#changePwd").removeClass("show");
        
                },
                error: function (xhr, ajaxOptions, thrownError) {
                    console.log(xhr.status);
                    console.log(xhr.responseText);
                    console.log(thrownError);
                }
            });
        
        });
    });

    function loadModels() {
 
        var manufacturer = $('#manufacturer').val();
    
        $(".other-model-wr").addClass( "d-none");
    
        if (manufacturer != 0) {
            $.ajax({
                type: 'get',
                dataType: 'json',
                url: baseURL +'/load/vehicleModel',
                data: {manufacturer: manufacturer},
                crossDomain: true,
                beforeSend: function () {
                    $('.model-wr .fa-chevron-down').addClass('d-none');
                    $('.model-wr .loader').removeClass('d-none');
                    $('.preloader').fadeIn();
                    $('#model').removeAttr('disabled');
                },
                success: function (response) {
    
                    var select = $('#model'); 
                    select.empty();
                    select.append("<option value=''>Select Model</option>");
                    for (var j = 0; j < response.length; j++) {
                        $("#model").append("<option value='" + response[j].model + "'>" + response[j].model + "  </option>");
                    }
                    if(response.length > 0){
                        select.append("<option value='other'>Other</option>");
                    }
                    // when no models available for the selected manufacture
                    if(response.length == 0) { 
                        $("#model").attr('disabled', 'true');
                        select.append("<option value='other' selected>Other</option>");
                        $('#other_model').val('');
                        $(".other-model-wr").removeClass( "d-none");
                    }
                },
                complete: function(){
                    // $('#model').removeAttr('disabled');
                    $('.model-wr .loader').addClass('d-none');
                    $('.model-wr .fa-chevron-down').removeClass('d-none');
                    $('.preloader').fadeOut();
                },
                error: function (xhr, ajaxOptions, thrownError) {
                    // console.log(xhr.status);
                    // console.log(xhr.responseText);
                    // console.log(thrownError);
                }
            });
        }
    }
    
    function searchCities() {
    
        var district = $('#district').val();
        
        if (district != 0) {
            $.ajax({
                type: 'get',
                //dataType: 'json',
                url: baseURL +'/search/cities',
                data: {district: district},
                crossDomain: true,
                beforeSend: function () {
                    $('.preloader').fadeIn();
                },
                success: function (response) {
                    var select = $('#city');
                    select.empty();
                    select.append("<option value=''>All Cities</option>");
                    for (var j = 0; j < response.length; j++) {
                        $("#city").append("<option value='" + response[j].city_name + "'>" + response[j].city_name + "</option>");
                    }
                    $('.preloader').fadeOut();
                },
                error: function (xhr, ajaxOptions, thrownError) {
                    $('.preloader').fadeOut();
                    console.log(xhr.status);
                    console.log(xhr.responseText);
                    console.log(thrownError);
                }
            });
        }
    }
    
    function getCities() { 
    
        var district = $('#district').val();
    
        $.ajax({
            type: 'get',
            dataType: 'json',
            url: baseURL +'/search/cities', 
            data: {district: district},
            crossDomain: true,
            beforeSend: function () {
                $('.area-wr .fa-chevron-down').addClass('d-none');
                $('.area-wr .loader').removeClass('d-none');
                $('.preloader').fadeIn();
            },
            success: function (response) {
                var select = $('#city');
                select.empty();
                select.append("<option value=''>Select City</option>");
                for (var j = 0; j < response.length; j++) {
                    $("#city").append("<option value='" + response[j].city_name + "'>" + response[j].city_name + "     </option>");
                }
            },
            complete: function(){
                $('#city').removeAttr('disabled');
                $('.area-wr .loader').addClass('d-none');
                $('.area-wr .fa-chevron-down').removeClass('d-none');
                $('.preloader').fadeOut();
            },
            error: function (xhr, ajaxOptions, thrownError) {
                $('.preloader').fadeOut();
                console.log(xhr.status);
                console.log(xhr.responseText);
                console.log(thrownError);
            }
        });
    }
    
    function getStreamsAndSpecializations() {
    
        var institute = $('#institute').val();
    
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
    
        $.ajax({
            type: 'get',
            dataType: 'json',
            url: baseURL +'/load/streamsAndSpecializations',
            data: {institute: institute},
            crossDomain: true,
            success: function (response) {
    
                var specialization = response[1];
                var stream = response[0];
    
                var select = $('#specialization');
                select.empty();
                var select = $('#stream');
                select.empty();
                $("#specialization").append("<option value='0'>--select specialization--</option>");
                $("#stream").append("<option value='0'>--select stream--</option>");
                for (var j = 0; j < specialization.length; j++) {
                    $("#specialization").append("<option value='" + specialization[j].id + "'>" + specialization[j].specialization + "     </option>");
                }
                for (var j = 0; j < stream.length; j++) {
                    $("#stream").append("<option value='" + stream[j].id + "'>" + stream[j].stream_name + "     </option>");
                }
            },
            error: function (xhr, ajaxOptions, thrownError) {
                console.log(xhr.status);
                console.log(xhr.responseText);
                console.log(thrownError);
            }
        });
    }
    
    function getspecializations() {
    
        var stream = $('#stream').val();
        $.ajax({
            type: 'get',
            //dataType: 'json',
            url: baseURL +'/load/specialization',
            data: {stream: stream},
            crossDomain: true,
            success: function (response) {
                var select = $('#specialization');
                select.empty();
                $("#specialization").append("<option value='0'>--select specialization--</option>");
                for (var j = 0; j < response.length; j++) {
                    $("#specialization").append("<option value='" + response[j].id + "'>" + response[j].specialization + "     </option>");
                }
            },
            error: function (xhr, ajaxOptions, thrownError) {
                console.log(xhr.status);
                console.log(xhr.responseText);
                console.log(thrownError);
            }
        });
    }
    
    function showContents() {
        var study_option = $("#study_option").val();
        if (study_option == 'local') {
            $("#first_content").hide();
            $("#second_content").hide();
            $("#third_content").hide();
        }
        if (study_option == 'other') {
            $("#first_content").show();
            $("#second_content").show();
            $("#third_content").hide();
        }
        if (study_option == 'overseas') {
            $("#first_content").hide();
            $("#second_content").hide();
            $("#third_content").show();
        }
    }

    function sendverificationcode() {

        var email = $('#email').val();

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: 'post',
            crossDomain: true,
            // dataType: 'json',
            url: baseURL +'/sendVerificationCode',
            data: {email: email},
        
            success: function (response) {
    
                if(response == '0'){
                    $("#div-vc").removeClass("d-none");
                    $(".e-password").addClass("d-none");
                    $("#password-email").val("");
                    $('#btnEditEmail').data('count','2');
                }else if(response == '2'){
                    $('#btnEditEmail').data('count',0);
                     $("#div-vc").addClass("d-none");
                    $(".evm-div").removeClass("d-none");
                    $(".e-password").addClass("d-none");
    
                    $("#password-email").val("");
                    $(".email-verify-message").text('The email has already been taken.');
                }else{
                
                    $('#btnEditEmail').data('count',0);
                    $("#div-vc").addClass("d-none");
                    $(".evm-div").removeClass("d-none");
                    $(".e-password").addClass("d-none");
                    $("#password-email").val("");
                    $(".email-verify-message").text('please enter valid email address.');
                }
            },
            error: function (xhr, ajaxOptions, thrownError) {
                console.log(xhr.status);
                console.log(xhr.responseText);
                console.log(thrownError);
            }
        });
    }

    function sendotp() {

        var phone = $('#phone_number').val();
    
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: 'post',
            crossDomain: true,
            // dataType: 'json',
            url: baseURL +'/send/otp/user',
            data: {phone: phone},
         
            success: function (response) {
                
                if (response == '0') {
                    $("#password-phone").val("");
                    $(".p-password").addClass("d-none");
                    $("#div-otp").removeClass("d-none");
                    $('#btnEditPhone').data('count',2);
                } else if(response == '2') {
    
                    $('#btnEditPhone').data('count',0);
                    $("#div-otp").addClass("d-none");
                    $("#password-phone").val("");
                    $(".p-password").addClass("d-none");
                    $(".phone-verification-code").removeClass("d-none");
                    $(".phone-verification-code-message").text("The phone number has already been taken.");
    
                } else {
                    $('#btnEditPhone').data('count',0);
                    $("#password-phone").val("");
                    $(".p-password").addClass("d-none");
                    $(".phone-verification-code").removeClass("d-none");
                    $(".phone-verification-code-message").text("please enter valid phone number.");
                }
                
            },
            error: function (xhr, ajaxOptions, thrownError) {
                console.log(xhr.status);
                console.log(xhr.responseText);
                console.log(thrownError);
            }
        });
    }

    function getPropertyTypes() {

        var category = $('#property_category').val();
        $.ajax({
            type: 'get',
            //dataType: 'json',
            url: baseURL +'/load/propertyTypes',
            data: {category: category},
            crossDomain: true,
            beforeSend: function () {
                $('.preloader').fadeIn();
            },
            success: function (response) {
                //alert(response);
                var select = $('#property_type');
                select.empty();
                select.append("<option value=''></option>");
                for (var j = 0; j < response.length; j++) {
                    //alert(response.id);
                    $("#property_type").append("<option value='" + response[j].id + "'>" + response[j].type + "</option>");
                }
            },
            complete: function(){
                $('.preloader').fadeOut();
            },
            error: function (xhr, ajaxOptions, thrownError) {
                $('.preloader').fadeOut();
                console.log(xhr.status);
                console.log(xhr.responseText);
                console.log(thrownError);
            }
        });
    }

    function loadPropertyTypes() {

        var category = $('#category').val();
        $.ajax({
            type: 'get',
            //dataType: 'json',
            url: baseURL +'/search/propertyTypes',  
            data: {category: category},
            crossDomain: true,
            beforeSend: function () {
                $('.preloader').fadeIn();
            },
            success: function (response) {
                var select = $('#type');
                select.empty();
                select.append("<option value=''>All Types</option>");
                for (var j = 0; j < response.length; j++) {
                    $("#type").append("<option value='" + response[j].id + "'>" + response[j].type + "     </option>");
                }
            },
            complete: function(){
                $('.preloader').fadeOut();
            },
            error: function (xhr, ajaxOptions, thrownError) {
                $('.preloader').fadeOut();
                console.log(xhr.status);
                console.log(xhr.responseText);
                console.log(thrownError);
            }
        });
    
    }
    
    function getPropertyCategories() {
    
        var type = $('#advertisement_type').val();
       
        $.ajax({
            type: 'get',
            //dataType: 'json',
            url: baseURL +'/load/propertyCategories',
            data: {type: type},
            crossDomain: true,
            beforeSend: function () {
                $('.preloader').fadeIn();
            },
            success: function (response) {
                var select = $('#property_category');
                select.empty();
                select.append("<option value=''></option>");
                for (var j = 0; j < response.length; j++) {
                    $("#property_category").append("<option value='" + response[j].id + "'>" + response[j].category + "</option>");
                }
                $('.preloader').fadeOut();
            },
            error: function (xhr, ajaxOptions, thrownError) {
                $('.preloader').fadeOut();
                console.log(xhr.status);
                console.log(xhr.responseText);
                console.log(thrownError);
            }
        });
    }

    // Watch List
    function addWatchList(ad_id) {

        var ad_id = ad_id;
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: 'post',
            crossDomain: true,
            //dataType: 'json',
            url: baseURL +'/create/user-watch-list',
            data: {ad_id: ad_id},
            beforeSend: function(){
                $('.watch-list.add-'+ad_id+' .preloader-sm').removeClass('d-none');
            },
            success: function (response) {
                // location.reload();
                $('.badge-two').text(response);
                $('.badge-one').text(response);
                $('.add-' + ad_id).addClass('d-none');
                $('.remove-' + ad_id).removeClass('d-none');
                $('.watch-list.add-'+ad_id+' .preloader-sm').addClass('d-none');
            },
            error: function (xhr, ajaxOptions, thrownError) {
                console.log(xhr.status);
                console.log(xhr.responseText);
                console.log(thrownError);
            }
        });
    }
    
    function removeWatchList(ad_id, status) {
    
        var ad_id = ad_id;
    
        console.log(ad_id);
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: 'post',
            crossDomain: true,
            //dataType: 'json',
            url: baseURL +'/remove/user-watch-list',
            data: {ad_id: ad_id},
            beforeSend: function(){
                $('.watch-list.remove-'+ad_id+' .preloader-sm').removeClass('d-none');
                $('.preloader').fadeIn();
            },
            success: function (response) {
                if (status == 1) {
                    // location.reload();
                    $('.watch-list-'+ad_id).remove();
                    if(response > 0){
                        $('.badge-one').text(response);
                        $('.badge-two').text(response);
                    }else{
                        $('.badge-one').hide();
                        $('.badge-two').hide();
                    }
                    
                } else {
    
                        if(response > 0){
                            $('.badge-one').text(response);
                            $('.badge-two').text(response);
                        }else{
                            $('.badge-one').hide();
                            $('.badge-two').hide();
                        }
                    $('.add-' + ad_id).removeClass('d-none');
                    $('.remove-' + ad_id).addClass('d-none');
                }
    
                $('.watch-list.remove-'+ad_id+' .preloader-sm').addClass('d-none');
                $('.preloader').fadeOut();
    
            },
            error: function (xhr, ajaxOptions, thrownError) {
                $('.watch-list.remove-'+ad_id+' .preloader-sm').addClass('d-none');
                $('.preloader').fadeOut();
                console.log(xhr.status);
                console.log(xhr.responseText);
                console.log(thrownError);
            }
        });
    }

    // Dealer Search Suggestions
    function searchVenders(category, dealerCategory, search) {
        if (search != '') {
            $.ajax({
                type: 'get',
                url: baseURL +'/search/venders',
                data: {  category: category,
                    dealerCategory: dealerCategory,
                    search: search },
                crossDomain: true,
                    
                beforeSend: function () {
                    $('#button-addon2 i').addClass('d-none');
                    $('#button-addon2 .spinner-border').removeClass('d-none');
                },
                success: function (response) {
                    var select = $('#vender-suggestions');
                    select.empty();
                    for (var j = 0; j < response.length; j++) {
                        select.append("<div class='item border-bottom p-2' style='cursor: pointer';>" + response[j].business_name + "</div>");
                    }

                    if (response.length > 0) {
                        $('.vender-suggestion-wr').removeClass('d-none');
                        if (response.length > 8) {
                            $('.vender-suggestion-wr').css('overflow-y', 'scroll');
                        } else {
                            $('.vender-suggestion-wr').css('overflow-y', 'auto');
                        }
                    } else {
                        $('.vender-suggestion-wr').addClass('d-none');
                    }
                },
                complete: function(){
                    $('#button-addon2 i').removeClass('d-none');
                    $('#button-addon2 .spinner-border').addClass('d-none');
                },
                error: function (xhr, ajaxOptions, thrownError) { }
            });
        }
        else {
            var select = $('#vender-suggestions');
            select.empty();
            $('.vender-suggestion-wr').addClass('d-none');
        }
    }

