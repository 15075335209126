$(document).ready(function() {

    // --- Home Page Vehicle installment - Select vehicle type --- //
    $('.installment-wr .vehicles .icon').on('click', function () {
        $('.preloader').fadeIn();
        const vtype = $(this).attr('data-vtype');
        $('.installment-wr #vehicleType').val(vtype);
        $("#installmentSubmit").trigger("click");
    });

    // Disable right click of images
    $('img').bind('contextmenu', function(e) {
        return false;
    });

    if($('.setmoney').val()){
        var intilalSetVal = numberWithCommas($('.setmoney').val());
        $('.getmoney').val(intilalSetVal);
    }
    
    // Add common thousand separator [start] //
    $('.getmoney').on('keyup', function() {
        var currval = this.value;
        // console.log(currval);
        var val = currval.replace(/[^0-9\.]/g,'');
        // console.log(val);
        if(val != "") {
            var valArr = val.split('.');
            valArr[0] = (parseInt(valArr[0],10)).toLocaleString();
            val = valArr.join('.');
        }
        this.value = val;
        $('.setmoney').val(currval.replace(/,/g, ''));
    });// Add common thousand separator [end] //
    
    // Common Blink
    function blink_text() {
        $('.blink').fadeOut(500);
        $('.blink').fadeIn(500);
    }
    setInterval(blink_text, 1000);

    // Password Show/Hide 
    $('#show-hide-pwd .show-pwd').on('click',  function(){
        console.log('clicked');
        if($(this).hasClass('visible')){
            $(this).removeClass('visible')
            $(this).children('.fa-eye').addClass('d-none');
            $(this).children('.fa-eye-slash').removeClass('d-none');
            $(this).siblings('#password').attr('type','password')
        } else {
            $(this).addClass('visible')
            $(this).children('.fa-eye').removeClass('d-none');
            $(this).children('.fa-eye-slash').addClass('d-none');
            $(this).siblings('#password').attr('type','text')
        }
    });

    $('#inputEflyer').on('change',function(){
        //get the file name
        var fileName = $(this).val();
        $(this).next('.custom-file-label').html(fileName);
    })

    // More Menu open
    $('.more-menu').on('click', function(){
        $('.more-menu-wr').addClass('open');
        $('body').addClass('more-menu');
    });

    // More Menu Close
    $('.more-menu-wr').on('click', function(e){
        if (e.target !== this)
        return;
        $(this).removeClass('open');
        $('body').removeClass('more-menu');
    });
    
    // Prevent Bootstrap dialog from blocking focusin
    $(document).on('focusin', function(e) {
        if ($(e.target).closest(".mce-window").length) {
        e.stopImmediatePropagation();
        }
    });

    // Scroll top button
    $('.scroll-top').hide(); 

    $('.scroll-top').click(function(){
        $("html, body").animate({ scrollTop: 0 }, 600);
        return false;
    });

    $(window).scroll(function (event) {
        var scroll = $(window).scrollTop();
        var h = window.innerHeight;
        if (scroll > h) {
            $('.scroll-top').fadeIn();
        }else {
            $('.scroll-top').fadeOut();
        }
    });

    // ------------------------View item Carasole----------------------------//
    $('#item-images').slick({ 
        lazyLoad: 'ondemand',
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: $('.item-img-wrapper .prev'),
        nextArrow: $('.item-img-wrapper .next'),
        // fade: true,
        asNavFor: '#item-thumbs'
    });

    $('#item-thumbs').slick({
        asNavFor: '#item-images',
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false, 
        focusOnSelect: true,
        focusOnChange: true
    });

    $('#similar-ad-rotator').slick({
        slidesToShow: 4,
        slidesToScroll: 2,
        infinite: false,
        prevArrow: $('.similar-ads .prev'),
        nextArrow: $('.similar-ads .next'),

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                slidesToShow: 3,
                slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings:"unslick"
            }
        ]
    });

    $('.suggested-ad-wr .rotate-wr').slick({ 
        slidesToShow: 1,
        slidesToScroll: 1, 
        speed: 1000,
        prevArrow: $('.suggested-ad-wr .prev'),
        nextArrow: $('.suggested-ad-wr .next'),
        // autoplay: true,
        // autoplaySpeed: 5000,
        centermode: true
    });
    
    $('.similar-ad-wr .rotate-wr').slick({ 
        slidesToShow: 4,
        slidesToScroll: 1, 
        prevArrow: $('.similar-ad-wr .prev'),
        nextArrow: $('.similar-ad-wr .next'),  
        autoplay: true,
        autoplaySpeed: 4000,
            
        responsive: [
            {
            breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
            breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
            breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('#category-rotator').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        dots: true,
        autoplaySpeed: 1300,
        fade: true,
        arrows: false,
        prevArrow: $('.cat-rotator .prev-arrow'),
        mnextArrow: $('.cat-rotator .mnext-arrow'),
    });
    
    $('[data-fancybox="item-images"]').fancybox({
        protect: true,
        buttons: [
            "zoom",
            "slideShow",
            "fullScreen",
            "thumbs",
            "close"
        ]
    });
    // ----------------------------View item carosoul END--------------------------------- //

    $(window).scroll(function(){
        if ($(this).scrollTop() > 0) {
        $('.header').addClass('on-scroll');
        } else {
        $('.header').removeClass('on-scroll'); 
        }
    });

    // --- Initialize tooltips (Post Advert)--- //
    $('.nav-tabs > li a[title]').tooltip();

    // --- Wizard (Post Advert) --- //
    $('a[data-toggle="tab"]').on('show.bs.tab', function (e) {
        var $target = $(e.target);
        if ($target.parent().hasClass('disabled')) {
            return false;
        }
    }); 
    
    // --- Customize Fix | Laravel Pegination --- //
    $('ul.pagination li').each(function(){
        $(this).addClass('page-item');
        $(this).children().addClass('page-link');
    });

    // --- Scroll To Perent item list (Home page) when Paginated --- //
    if( !(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) && $('#app .common-section').hasClass('scroll-here') ) {
        var paginatedScrollPoint = $('.scroll-here').offset().top - 100;
        
        $('html, body').animate({
            scrollTop: paginatedScrollPoint
        }, 'fast'); 
    }

});

var numberWithCommas = function(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}